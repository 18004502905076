import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListOl } from '@fortawesome/free-solid-svg-icons';
import { tabletL, tabletS } from 'styles/responsive';

interface TableOfContentsType {
  tableOfContents: string;
}

const TOCContainer = styled.div`
  padding: 100px 0;
  padding-left: 4%;

  @media (max-width: ${tabletS}) {
    display: none;
  }
`;

const TOCWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: sticky;
  top: 100px;
  width: 250px;

  @media (max-width: ${tabletL}) {
    width: 200px;
  }
`;

const Title = styled.div`
  display: flex;
  gap: 10px;
  font-size: 18px;
`;

const TOC = styled.nav`
  margin-left: 10px;
  padding-left: 20px;
  border-left: 2px solid var(--point-color);

  ul {
    margin-left: 10px;
  }
  li,
  p {
    margin-bottom: 10px;
  }
  li {
    list-style: none;
    width: fit-content;
    color: var(--text-color);

    a {
      &:hover,
      &.active {
        color: var(--point-color);
      }
      &.active {
        border-bottom: 1px solid var(--point-color);
      }
    }
  }
  .check-list {
    display: inline-flex;
  }
  .check-icon {
    margin-bottom: 0;
  }
`;

export default function TableOfContents({
  tableOfContents,
}: TableOfContentsType) {
  return (
    <TOCContainer>
      <TOCWrapper>
        <Title>
          <FontAwesomeIcon icon={faListOl} />
          Table Of Contents
        </Title>
        <TOC dangerouslySetInnerHTML={{ __html: tableOfContents }} />
      </TOCWrapper>
    </TOCContainer>
  );
}
