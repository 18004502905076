import styled from '@emotion/styled';
import { Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { mobile, tabletS } from 'styles/responsive';

export interface PostHeadInfoType {
  title: string;
  date: string;
  categories: string;
}

const PostHeadInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 2;
  width: 65%;
  height: 100%;
  margin: 0 auto;
  padding: 65px 0;
  color: #ffffff;

  @media (max-width: ${tabletS}) {
    padding: 50px 0;
  }

  @media (max-width: ${mobile}) {
    width: 100%;
    margin: 0;
    padding: 25px;
  }
`;

const PrevIcon = styled.div`
  display: grid;
  place-items: center;
  z-index: 2;
  width: 40px;
  height: 40px;
  border: 1px solid rgb(255 255 255 / 18%);
  border-radius: 50%;
  background: rgb(255 255 255 / 30%);
  color: var(--post-point);
  font-size: 22px;
  cursor: pointer;

  @media (max-width: ${mobile}) {
    width: 30px;
    height: 30px;
    font-size: 18px;
  }
`;

const Category = styled.div`
  z-index: 2;
  margin: 0 auto;
  padding: 5px 20px;
  border-radius: 12px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;

  @media (max-width: ${mobile}) {
    margin-top: 70px;
    margin-left: 0;
    padding: 5px 10px;
    font-size: 14px;
  }
`;

const Title = styled.div`
  display: -webkit-box;
  z-index: 2;
  overflow: hidden;
  overflow-wrap: break-word;
  margin: auto;
  font-size: 44px;
  font-weight: 600;
  text-align: center;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media (max-width: ${tabletS}) {
    font-size: 38px;
  }

  @media (max-width: ${mobile}) {
    font-size: 24px;
    text-align: left;
  }
`;

const Date = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  z-index: 2;
  width: auto;
  margin: 0 auto;
  font-size: 16px;

  @media (max-width: ${mobile}) {
    position: absolute;
    top: 30px;
    right: 25px;
    margin: 0;
    font-size: 14px;
    text-align: right;
  }
`;

export default function PostHeadInfo({
  title,
  date,
  categories,
}: PostHeadInfoType) {
  const goBackPage = () => window.history.back();

  const categoryColor = (categories: string) => {
    let background = '';
    switch (categories) {
      case 'MarkUp':
        background = '#2c2f3d';
        break;
      case 'CSS':
        background = '#3288f1';
        break;
      case 'Javascript':
        background = '#ffc300';
        break;
      case 'Typescript':
        background = '#005fdc';
        break;
      case 'React':
        background = '#61dafb';
        break;
      case 'Redux':
        background = '#8F43EE';
        break;
      case 'Node.js':
        background = '#54B435';
        break;
      case 'Gatsby':
        background = '#663399';
        break;
      case 'Algorithm':
        background = '#00ABB3';
        break;
      case 'Git':
        background = '#E14D2A';
        break;
      case '개발 지식':
        background = '#fe5d9f';
        break;
      case 'DB':
        background = '#AD8E70';
        break;
      case 'UX/UI':
        background = '#D14D72';
        break;
      default:
        background = '#000000';
    }
    return background;
  };

  return (
    <PostHeadInfoWrapper>
      <PrevIcon onClick={goBackPage}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </PrevIcon>
      <Category style={{ background: `${categoryColor(categories)}` }}>
        <Link to={`/categories/${kebabCase(categories)}/`}>{categories}</Link>
      </Category>
      <Title>{title}</Title>
      <Date>
        <FontAwesomeIcon icon={faCalendar} />
        {date}
      </Date>
    </PostHeadInfoWrapper>
  );
}
