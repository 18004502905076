import { MutableRefObject, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { mobile, tabletS } from 'styles/responsive';
import { PostFrontmatterType } from 'types/PostItem.types';
import Template from 'components/templates/MainTemplate';
import PostHead from 'components/organisms/post/PostHead';
import PostContent from 'components/organisms/post/PostContent';
import CommentWidget from 'components/molecules/widget/CommentWidget';
import Footer from 'components/organisms/footer/Footer';

interface PostTemplateType {
  data: {
    markdownRemark: {
      html: string;
      frontmatter: PostFrontmatterType;
      tableOfContents: string;
    };
    site: {
      siteMetadata: {
        siteUrl: string;
      };
    };
  };
  pageContext: {
    slug: string;
  };
}

const PostContainer = styled.div`
  position: relative;
  margin: 30px 30px 30px 0;
  padding-bottom: 50px;
  border-radius: 10px;
  background: var(--post-content-bg);
  box-shadow: var(--main-content-shadow);

  @media (max-width: ${tabletS}) {
    border-radius: 0;
    box-shadow: 0 20px 30px -10px rgb(0 0 0 / 20%);
  }

  @media (max-width: ${mobile}) {
    margin: 0;
    border-radius: 0;
    background: var(--mobile-post-bg);
  }
`;

export default function PostTemplate({
  data: {
    markdownRemark: { html, frontmatter, tableOfContents },
    site: {
      siteMetadata: { siteUrl },
    },
  },
  pageContext: { slug },
}: PostTemplateType) {
  let observer: MutableRefObject<IntersectionObserver | null> =
    useRef<IntersectionObserver>(null);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const selectedHElement = entry.target.getAttribute('href');

          if (entry.intersectionRatio > 0) {
            document.querySelectorAll('nav a').forEach((el) => {
              el.classList.remove('active');
            });
            document
              .querySelector(`nav li a[href="${selectedHElement}"]`)
              .classList.add('active');
          }
        });
      },
      { rootMargin: '0% 0% -65% 0%' },
    );

    document.querySelectorAll('.anchor').forEach((el) => {
      observer.current.observe(el);
    });
  }, []);

  const title = frontmatter.title;
  const description = frontmatter.summary;
  const image = `${siteUrl}${frontmatter.thumbnail.publicURL}`;
  const url = `${siteUrl}${slug}`;

  return (
    <Template title={title} description={description} image={image} url={url}>
      <PostContainer>
        <PostHead
          title={title}
          date={frontmatter.date}
          categories={frontmatter.categories}
          thumbnail={frontmatter.thumbnail.childImageSharp.gatsbyImageData}
        />
        <PostContent
          html={html}
          tableOfContents={tableOfContents}
          tags={frontmatter.tags}
        />
        <CommentWidget />
        <Footer />
      </PostContainer>
    </Template>
  );
}

export const queryMarkdownDataBySlug = graphql`
  query queryMarkdownDataBySlug($slug: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMM DD, YYYY")
        summary
        tags
        categories
        thumbnail {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 2280, breakpoints: [2280])
          }
        }
      }
      tableOfContents
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export type PostPageItemType = {
  node: {
    html: string;
    frontmatter: PostFrontmatterType;
  };
};
