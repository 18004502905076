import styled from '@emotion/styled';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { mobile } from 'styles/responsive';
import PostHeadInfo, {
  PostHeadInfoType,
} from 'components/molecules/info/PostHeadInfo';

interface GatsbyImgType {
  className?: string;
  image: IGatsbyImageData;
  alt: string;
}

interface PostHeadType extends PostHeadInfoType {
  thumbnail: IGatsbyImageData;
}

const PostHeadWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 410px;

  img {
    border-radius: 10px 10px 0 0;
  }

  @media (max-width: ${mobile}) {
    display: flex;
    flex-direction: column;
    height: 270px;
    img {
      border-radius: 0;
    }
  }
`;

const BackgroundImage = styled(({ ...props }: GatsbyImgType) => (
  <GatsbyImage {...props} />
))`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 410px;
  object-fit: cover;
  filter: brightness(0.65);

  @media (max-width: ${mobile}) {
    height: 270px;
  }
`;

export default function PostHead({
  title,
  date,
  categories,
  thumbnail,
}: PostHeadType) {
  return (
    <PostHeadWrapper>
      <BackgroundImage image={thumbnail} alt="thumbnail" />
      <PostHeadInfo title={title} date={date} categories={categories} />
    </PostHeadWrapper>
  );
}
