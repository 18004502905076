import { css } from '@emotion/react';
import { mobile, tabletL } from './responsive';

const dot = css`
  content: '';
  display: block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: var(--md-dots);
`;

export const MarkdownStyle = css`
  // Renderer Style
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  word-break: break-all;

  // Markdown Style
  line-height: 1.8;
  font-size: 18px;
  font-weight: 400;

  // Apply Padding Attribute to All Elements
  p {
    padding: 3px 0;
  }

  // Image Element Style
  img {
    width: 80%;
    margin: 10px 10%;
  }

  .gatsby-resp-image-wrapper {
    width: 80%;
    margin: 15px 0 35px 0;
  }

  // Heading Element Style
  h1,
  h2,
  h3 {
    margin-top: 45px;
    margin-bottom: 10px;
    color: var(--post-point-text);

    svg {
      fill: var(--post-point-text);
    }
  }
  h4 {
    font-weight: 400;
  }
  * + h1,
  * + h2,
  * + h3 {
  }
  hr + h1,
  hr + h2,
  hr + h3 {
    margin-top: 0;
  }

  h2 + h3 {
    margin-top: 10px;
  }

  h1 {
    font-size: 30px;
  }
  h2 {
    padding-bottom: 5px;
    font-size: 25px;
    border-bottom: 1px solid var(--post-hr);
  }
  h3 {
    font-size: 20px;

    .sub-head {
      margin: 40px 0 20px 0;
      padding-left: 15px;
      border-left: 3px solid var(--post-point);
    }
    .check-list {
      display: inline-flex;
      vertical-align: middle;
      color: #ff7396;
      line-height: 25px;
    }
    .check-icon {
      display: inline-block;
      width: 25px;
      height: 25px;
      margin-right: 15px;
      border: 2px solid #ff7396;
      border-radius: 50%;
      background: none;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      color: #ff7396;
      font-size: 18px;
      line-height: 18px;
      text-align: center;
    }
  }

  h3 + .check-blockquote {
    margin-top: 10px;
    margin-left: 10px;
    padding: 0 25px;
    background: none;
    border-left: 2px solid #ff7396;
    border-radius: 0;
    box-shadow: none;
  }

  strong {
    color: var(--post-point-text);
  }

  // Quotation Element Style
  blockquote {
    margin: 16px 0;
    padding: 20px 28px 24px 28px;
    border-left: none;
    border-radius: 8px;
    background: var(--quote-bg);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.35);

    th,
    td {
      border-bottom: 1px solid rgb(239 250 255 / 15%);
      border-radius: 0;
      background: #011627;
      color: #abb2bf;
      font-size: var(--fs-base);
    }

    hr {
      margin-bottom: 0;
    }
    code {
      font-weight: 100;
    }
    span {
      color: var(--point-color);
    }

    img {
      width: 50%;
      margin: 8px 25%;
    }
  }

  .quotation {
    position: relative;
    margin: 40px 0;
    padding-top: 30px;
    border-radius: 8px;
    background: var(--quote-bg);
    box-shadow: 2px 2px 4px rgb(0 0 0 / 20%);
    font-style: italic;
    text-align: center;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 8px;
      box-shadow: inset -2px -2px 1px var(--quote-shadow-right),
        inset 2px 2px 4px var(--quote-shadow-left);
    }

    &::after {
      content: '❝';
      position: absolute;
      top: -2px;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50px;
      height: 50px;
      border-radius: 50px;
      background: var(--post-content-bg);
      box-shadow: 0 4px 5px 0 rgb(0 0 0 / 20%);
      color: var(--point-color);
      font-size: 32px;
      font-style: normal;
    }
  }

  // List Element Style
  ol,
  ul {
    margin-left: 15px;
  }

  ul {
    list-style-type: '-';

    li {
      padding-left: 10px;
    }
  }

  // Horizontal Rule style
  hr {
    margin-bottom: 30px;

    &.cut-line {
      margin-top: 70px;
      border: none;
      border-top: 2px dashed var(--md-dots);

      &:after {
        content: '\u2702';
        display: inline-block;
        position: relative;
        top: -15px;
        left: 40px;
        padding: 0 3px;
        color: var(--md-dots);
        font-size: 20px;
      }
    }

    &.title-line {
      height: 1px;
      border: 0;
      background: linear-gradient(
        to right,
        #f0f0f0,
        #5433ff,
        #20bdff,
        #a5fecb,
        #f0f0f0
      );
    }

    &.dots-line {
      position: relative;
      overflow: visible;
      margin: 50px auto;
      border: 0;
      ${dot}

      &:before {
        position: absolute;
        left: -32px;
        ${dot}
      }
      &:after {
        position: absolute;
        left: 32px;
        ${dot}
      }
    }
  }

  // Link Element Style
  a {
    color: #4263eb;
    text-decoration: underline;
  }

  .card {
    width: 100%;
    height: 200px;
    background: #ffffff;
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }

  // Code Element Style
  deckgo-highlight-code {
    position: relative;
    margin: 8px 0 12px 0;
    font-size: 16px;
    tab-size: 2;

    &::after {
      content: attr(language);
      position: absolute;
      top: 10px;
      right: 18px;
      color: rgba(255, 255, 255, 0.8);
      font-size: 13px;
    }
  }

  code:not(pre > code) {
    padding: 3px 5px;
    background: var(--code-bg);
    color: var(--code-text);
    font-size: 15px;
  }

  // Checkbox Element Style
  input[type='checkbox'] {
    display: none;
  }
  input[id='checkbox'] + label {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    vertical-align: middle;
    border: 2px solid var(--post-checkbox-border);
    border-radius: 4px;
    background: var(--main-content-bg);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  }

  // key point Element Style
  .key-point {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
    color: #4e64f9;
    font-size: 20px;
    font-weight: 600;
  }
  .key-point-icon {
    font-size: 24px;
  }
  .key-blockquote {
    margin-top: 0;
    margin-left: 7px;
    padding: 0 15px;
    background: none;
    border-left: 3px solid #4e64f9;
    border-radius: 0;
    box-shadow: none;
  }

  // Link Card Element Style
  .summary-card {
    line-height: 1.5;
    margin-top: 15px;
    border-radius: 8px;
    transition: 0.4s;

    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

      .summary-card__image {
        opacity: 0.9;
      }
      .summary-card__description__title {
        color: #3d17fc;
      }
    }
  }

  .summary-card a {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: var(--link-card-border);
    border-radius: 8px;
    color: inherit;
    text-decoration: none;
  }
  .summary-card__image {
    width: 14rem;
    height: 10rem;
    margin: 0;
    object-fit: cover;
    border-radius: 8px 0 0 8px;
  }
  .summary-card__description {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    padding: 0 1.2rem;
  }
  .summary-card__description__title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    max-height: calc(2rem * 1.5);
    font-weight: 600;
  }
  .summary-card__description__summary {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    max-height: calc(1.6rem * 1.5);
    margin-block-start: 0.4rem;
    color: #a3a3a3;
    font-size: 0.8rem;
  }
  .summary-card__description__url {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-block-start: 0.6rem;
    color: #a3a3a3;
    font-size: 0.8rem;
    text-overflow: ellipsis;
  }

  /* Markdown Responsive Design */
  @media (max-width: ${tabletL}) {
    margin: 0 auto;
  }

  @media (max-width: ${mobile}) {
    width: 100%;
    padding-bottom: 50px;
    font-size: 16px;
    line-height: 1.8;

    h1 {
      font-size: 23px;
    }
    h2 {
      font-size: 20px;
    }
    h3 {
      font-size: 17px;
      .check-list {
        line-height: 20px;
      }
      .check-icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        font-size: 14px;
        line-height: 12px;
      }
    }

    h3 + .check-blockquote {
      padding: 0 20px;
    }

    // Image Element Style
    img {
    }
    .gatsby-resp-image-wrapper {
      margin: 15px 0;
    }

    // hr Element Style
    hr {
      margin-bottom: 20px;

      &.cut-line {
        margin-top: 50px;
      }

      &.dots-line {
        margin: 40px auto;
        width: 5px;
        height: 5px;

        &:before,
        &:after {
          width: 5px;
          height: 5px;
        }
      }
    }

    // Code block Element Style
    deckgo-highlight-code {
      font-size: 14px;
    }
    code:not(pre > code) {
      padding: 2px 4px;
      font-size: 13px;
    }

    // Quotation Element Style
    blockquote {
      margin: 25px 0;
      padding: 10px 20px;
      border-radius: 8px;
      line-height: 1.8;

      th,
      td {
        font-size: var(--fs-xs);
      }
    }

    .quotation {
      padding-top: 30px;
      margin: 30px 0;

      &::after {
        width: 40px;
        height: 40px;
        font-size: 32px;
      }
    }

    // Checkbox Element Style
    input[id='checkbox'] + label {
      width: 16px;
      height: 16px;
    }

    // key point Element Style
    .key-point {
      font-size: 18px;
    }
    .key-point-icon {
      font-size: 22px;
    }

    // Link card Element Style
    .summary-card {
      width: 80%;
      margin: 15px auto;
    }
    .summary-card a {
      flex-direction: column;
      padding: 10px;
    }
    .summary-card__image {
      width: 100%;
      height: 7rem;
    }
    .summary-card__description {
      margin-top: 10px;
      padding: 0;
    }
    .summary-card__description__title {
      font-size: 1rem;
    }
    .summary-card__description__summary {
      margin-block-start: 0.2rem;
      font-size: 0.6rem;
    }
    .summary-card__description__url {
      overflow: hidden;
      margin-block-start: 0.2rem;
      font-size: 0.4rem;
    }
  }
`;
