import { createRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { mobile, tabletL } from 'styles/responsive';

const src = 'https://utteranc.es/client.js';
const repo = 'nxnaxx/nxnaxx-comments';

interface UtterancesAttributesType {
  src: string;
  repo: string;
  'issue-term': string;
  label: string;
  theme: string;
  crossorigin: string;
  async: string;
}

const UtterancesWrapper = styled.div`
  padding: 40px 0;

  @media (max-width: ${tabletL}) {
    width: 80%;
    margin: 0 auto;
  }

  @media (max-width: ${mobile}) {
    width: 100%;
    padding: 40px 25px;
  }
`;

export default function CommentWidget() {
  const element = createRef<HTMLDivElement>();

  useEffect(() => {
    const themeColor = window.__theme === 'dark' ? 'dark-blue' : 'github-light';

    if (element.current === null) return;

    const utterances: HTMLScriptElement = document.createElement('script');
    const attributes: UtterancesAttributesType = {
      src,
      repo,
      'issue-term': 'pathname',
      label: 'Comment',
      theme: themeColor,
      crossorigin: 'anonymous',
      async: 'true',
    };

    Object.entries(attributes).forEach(([key, value]) => {
      utterances.setAttribute(key, value);
    });

    element.current.appendChild(utterances);
  }, []);

  return <UtterancesWrapper ref={element} />;
}
