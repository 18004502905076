import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHashtag } from '@fortawesome/free-solid-svg-icons';
import { mobile, tabletS } from 'styles/responsive';
import { MarkdownStyle } from 'styles/PostStyle';
import PostTagList from 'components/organisms/post/PostTagList';
import TableOfContents from 'components/molecules/toc/TableOfContents';

interface PostContentType {
  html: string;
  tableOfContents: string;
  tags?: string[];
}

const PostContentContainer = styled.div`
  display: flex;
  margin: 0 4%;
  border-bottom: var(--link-card-border);

  @media (max-width: ${tabletS}) {
    margin: 0;
  }

  @media (max-width: ${mobile}) {
    padding: 0 25px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-left: auto;

  @media (max-width: ${tabletS}) {
    width: 80%;
    margin: 0 auto;
  }

  @media (max-width: ${mobile}) {
    width: 100%;
    margin: 0;
  }
`;

const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 2;
  padding: 40px 0;
  color: var(--point-text-color);

  @media (max-width: ${mobile}) {
    padding: 25px 0;
    font-size: 14px;
  }
`;

const MarkdownRenderer = styled.div`
  ${MarkdownStyle}
`;

export default function PostContent({
  html,
  tableOfContents,
  tags,
}: PostContentType) {
  return (
    <PostContentContainer>
      <ContentWrapper>
        <TagWrapper>
          <FontAwesomeIcon icon={faHashtag} />
          <PostTagList tags={tags} />
        </TagWrapper>
        <MarkdownRenderer dangerouslySetInnerHTML={{ __html: html }} />
      </ContentWrapper>
      <TableOfContents tableOfContents={tableOfContents} />
    </PostContentContainer>
  );
}
